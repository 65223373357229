<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="标题" prop="title">
        <el-input v-model="info.title" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="分类" prop="pid">
        <el-select v-model="info.pid" placeholder="请选择">
          <el-option-group
              v-for="group in menuList"
              :key="group.id"
              :label="group.title">
            <el-option
                v-for="item in group.children"
                :key="item.id"
                :label="item.title"
                :value="item.id">
            </el-option>
          </el-option-group>
<!--          <el-option-->
<!--              v-for="item in menuList"-->
<!--              :key="item.id"-->
<!--              :label="item.title"-->
<!--              :value="item.id">-->
<!--          </el-option>-->
        </el-select>
      </el-form-item>
      <el-form-item label="图片" prop="img">
        <SelectImgs :selectNum="1" :selectData="[info.img]" :selectFunc="picChoose" :idx="1"></SelectImgs>
        <div style="margin-top: 40px">500*500px</div>
      </el-form-item>
      <el-form-item label="Banner" prop="banner">
        <SelectImgs :selectNum="1" :selectData="[info.banner]" :selectFunc="picChoose" :idx="2"></SelectImgs>
        <div style="margin-top: 40px">1920*680px</div>
      </el-form-item>
      <el-form-item label="产品大图" prop="img_big">
        <SelectImgs :selectNum="1" :selectData="[info.img_big]" :selectFunc="picChoose" :idx="3"></SelectImgs>
        <div style="margin-top: 40px">750*460px</div>
      </el-form-item>
      <el-form-item label="简介">
        <el-input type="textarea" rows="4" v-model="info.intro" placeholder="请输入简介"></el-input>
      </el-form-item>
      <el-form-item label="特征" prop="characteristic">
        <el-button type="primary" @click="addChar()">新增</el-button>
        <ul class="picList mg-tp-10">
          <li class="item" style="width: 204px;" v-for="(item,index) in info.characteristic" :key="index">
            <div class="img">
              <SelectImgs :selectNum="1" :selectData="[item.img]" :selectFunc="charChoose" :idx="index"></SelectImgs>
            </div>
            <el-input type="textarea" rows="3" v-model="item.title" placeholder="请输入标题"></el-input>
            <div class="spot" @click="delChar(index)"><i class="el-icon-delete"></i></div>
          </li>
        </ul>
      </el-form-item>
      <el-form-item label="配置参数" prop="params">
        <el-button type="primary" @click="addParams()">新增</el-button>
        <ul class="picList widt mg-tp-10">
          <li class="item" style="width: 204px; text-align: center" v-for="(item,index) in info.params" :key="index">
            <div class="img">
              <SelectImgs :selectNum="1" :selectData="[item.img]" :selectFunc="paramChoose" :idx="index"></SelectImgs>
            </div>
            <el-input type="textarea" rows="3" v-model="item.title" placeholder="请输入标题"></el-input>
            <el-input type="textarea" rows="3" class="mg-tp-5" v-model="item.desc" placeholder="请输入描述"></el-input>
            <div class="spot" @click="delParam(index)"><i class="el-icon-delete"></i></div>
          </li>
        </ul>
      </el-form-item>
      <el-form-item label="文件">
        <div style="display: flex; align-items: center">
          <video-upload :selectFunc="fileChoose"></video-upload>
          <span v-if="info.file">{{info.file.title}}</span>
          <el-button type="text" v-if="info.file && info.file.title" @click="info.file={}">删除文件</el-button>
        </div>
      </el-form-item>
      <el-form-item label="详情" prop="content">
        <wang-edit :txt="info.content" :callback="setText"></wang-edit>
      </el-form-item>
      <el-form-item label="排序(倒序)" prop="ordering">
        <el-input v-model="info.ordering" placeholder="请输入序号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="reBack()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

import SelectImgs from '../../components/SelectImgs'
import VideoUpload from "@/components/VideoUpload";
import WangEdit from "../../components/WangEdit";
export default {
  data() {
    return {
      menuList: [],
      info: {
        id: 0,
        pid: '',
        title: '',
        img: '',
        banner: '',
        img_big: '',
        intro: '',
        characteristic: [],
        params: [],
        file: false,
        content: ''
      },
      source_domain: this.config.SOURCE_DOMAIN,
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        pid: [
          { required: true, message: '请选择类目', trigger: 'blur' }
        ],
      }
    };
  },
  created() {
    this.getMenuList()
    if(this.$route.query.id) {
      this.info.id = this.$route.query.id
      this.getInfo()
    }
  },
  components: {
    SelectImgs,
    VideoUpload,
    WangEdit
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    setText(txt) {
      this.info.content = txt
    },
    addChar() {
      this.info.characteristic.push({
        img: '',
        title: ''
      })
    },
    addParams() {
      this.info.params.push({
        img: '',
        title: '',
        desc: ''
      })
    },
    delChar(idx) {
      this.info.characteristic.splice(idx,1)
    },
    delParam(idx) {
      this.info.params.splice(idx,1)
    },
    charChoose(item,idx) {
      this.info.characteristic[idx].img = item.pic
    },
    paramChoose(item,idx) {
      this.info.params[idx].img = item.pic
    },
    fileChoose(item) {
      this.info.file = item
    },
    picChoose(item,idx) {
      if(idx==1) {
        this.info.img = item.pic
      } else if(idx==2) {
        this.info.banner = item.pic
      } else if(idx==3) {
        this.info.img_big = item.pic
      }
    },
    getMenuList() {
      var that = this
      this.$api.goods.goodsMenuIndex({},function (res) {
        if(res.errcode == 0) {
          that.menuList = res.data
        }
      })
    },
    getInfo() {
      var that = this
      this.$api.goods.goodsInfo({id: that.info.id}, function (res) {
        if(res.errcode == 0) {
          that.info = res.data
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = that.info
          this.$api.goods.goodsEdit(param,function (res) {
            if(res.errcode == 0) {
              that.success(res.errmsg)
              that.reBack()
            } else {
              that.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style>
.widt .default {
  float: unset !important;
  margin: unset !important;
}
</style>
<style scoped>
.box {
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  min-height: 500px;
}
</style>
